<template>
  <div class="login-container">
    <div class="container-box">
      <div class="title">星云互联 | 许可证平台</div>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        aria-autocomplete="on"
        label-position="left"
      >
        <el-form-item prop="username">
          <span class="loginAccount"> 用户名 </span>
          <el-input
            ref="username"
            maxlength="20"
            v-model="loginForm.username"
            placeholder="用户名"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <div class="loginPassword">密码</div>
          <!-- <span>忘记密码？</span> -->
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          ></el-input>
        </el-form-item>
        <!-- <el-checkbox :model='checked' class='loginCheckbox'>记住密码</el-checkbox> -->
        <el-button
          class="button"
          :loading="loading"
          style="width: 100%"
          @click.native.prevent="handleLogin"
          >登录</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import {verifylogin,ajaxLogin,login,loginWebAjax,verify} from '@/api/user';
import $ from 'jquery';
export default {
  name: "Login",
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("手机号/账号不能为空"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        token:"",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  mounted(){
     
  },
  
  created(){
  },
  methods: {
    // isLogin(){
    //     verifylogin().then((res) => {
    //       if(res.result === false){
    //         let url = 'http://192.168.1.104:30000/sso/login?appId=5&device=Device_Windows&mode=1&redirectUrl=http://192.168.1.101:8082/';
    //         window.location.href = url+'#/login';
    //       }
    //     })
    // },
    getQueryVariable(key){
    var result = {};
        var paramStr = encodeURI(window.document.location.search);
        if (paramStr) {
            paramStr = paramStr.substring(1);
            var params = paramStr.split('&');
            for (var p = 0; p < params.length; p++) {
                result[params[p].split('=')[0]] = unescape(params[p].split('=')[1]);
            }
        }
        return result[key];
  },
    windowHref(token){
      window.location.href = Config.userCenter + '/login/addCookie?token='+token+'&redirectUrl='+Config.urlHome;
    },
    handleLogin(){
      let _this = this;
      let bcrypt = require('bcryptjs');
      _this.loginForm.password = bcrypt.hashSync(_this.loginForm.password,'$2a$10$4jEhSVFpf/9skmxQo4VNtO')
      loginWebAjax({
        loginType:'1',
        principal:_this.loginForm.username,
        credential:_this.loginForm.password,
        projectValue:'nlauth',
        passLogin:false
      }).then((res) => {
         if(res.code == 200){
           let token = res.data.token;
           if(token){
             if(token){
                _this.windowHref(token);
             }
           }
         }else{
           _this.$router.push('/login');
           _this.$message.error(res.msg);
         }
      })
//       $.ajax({
//         type : "POST",
//         contentType: "application/json;charset=UTF-8",
//         url :Config.url100 + "/ajaxLogin",
//         data:JSON.stringify(data),
//         success : function(result) {
//           if(result.status === 0){
//             let token = result.result.slice(6);
//             _this.token = token;
//             let url = Config.url20001+'/verify';
//             $.ajax({
//               url:url,
//               type:'POST',
//               headers: {
//     　　        'token':_this.token,
//                 'device':'Device_Windows',
//                 'appId':'5'
//               },
//               contentType:'application/json;charset=utf-8',
//               data:JSON.stringify(),
//               success:function(res){
//                 if(res.status === 0){
//                   localStorage.setItem("token",_this.token);
//                   localStorage.setItem("account",res.result.account.username);
//                   localStorage.setItem('userId',res.result.account.id);
//                   _this.$message.success("登录成功");
//                   _this.$router.push({
//                     path:'/home'
//                   });
//                 }else{
//                   return;
//                 }
//               },
//               error:function(err){

//               },
//               complete:function(xhr){

//               }
//             })
//           }else{
//             let message = result.message.slice(16)
//             _this.$message.error(decodeURI(message));
//             return;
//           }   
//         },
//         error : function(e){
//         },
//         complete:function(xhr){
//           if(xhr.status === 200){
            
//           }
//         }
// })
    }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assets/loginlogo.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-box {
  width: 630px;
  height: 735.4px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 24px;
}
.title {
  font-size: 32px;
  text-align: center;
  margin-top: 90px;
  color: #202224;
  margin-bottom: 76px;
}
.button {
  background: #233463;
  height: 56px;
  width: 418px !important;
  margin-left: 57px;
  font-size: 20px;
  color: #ffffff;
  font-family: "PingFangSC-Regular";
  margin-top: 55px;
}
.login-form {
  margin-left: 57px;
  margin-right: 57px;
}
.loginAccount {
  font-size: 18px;
  color: #202224;
}
.loginPassword {
  font-size: 18px;
  color: #202224;
  margin-top: 20px;
}
.loginCheckbox {
  margin-top: 14px;
}
.el-button:hover {
  background-color: #233463;
  color: #ffffff;
}
</style>